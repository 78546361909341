import React from "react";
import { Link } from "gatsby";

import * as style from "./nav.module.css";

export default function Nav({ hasHeight }) {
  return (
    <header className={hasHeight && style.hasHeight}>
      <nav>
        <Link className={style.homeLink} to="/">
          D<span className={style.c2}>i</span>ver
          <span className={style.c3}>sa</span>
        </Link>
      </nav>
    </header>
  );
}
