import React, { Fragment } from "react";

import Seo from "./seo";
import Nav from "./nav";
import Footer from "./footer";

export default function PageLayout({ children, seo, mainClassName, navProps }) {
  return (
    <Fragment>
      <Seo
        {...seo}
        description="Build a more inclusive world and make the future happen NOW"
        d
      />
      <Nav {...navProps} />

      <main className={mainClassName}>{children}</main>

      <Footer />
    </Fragment>
  );
}
