import React from "react";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";

import image from "../images/bg.png";

const BASE_URL = "https://diversa-europe.eu";

export default function SEO({ title, description }) {
  const location = useLocation();
  const url = `${BASE_URL}${location.pathname}`;
  const imageUrl = `${BASE_URL}${image}`;

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={imageUrl} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
}
