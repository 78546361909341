import React from "react";
import { Link } from "gatsby";

import * as style from "./footer.module.css";

export default function Footer() {
  return (
    <footer>
      <div>© Elshad Shirinov 2021</div>
      <div className={style.links}>
        <Link to="/imprint">Imprint</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
    </footer>
  );
}
